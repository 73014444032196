import React from 'react';
import { Box, Stack, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
//import { AuthButton, UserAvatar, SidebarLinks, SocialLinks } from "../../molecules";
import { SidebarLinks } from '../../molecules';
import {SocialMediaMobile} from '../';
import { Logo } from '../../atoms';

const SidebarHeader = styled(Stack)({
    height: '70px',
    backgroundColor: '#0B0B0F',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
});

const SidebarWrapper = styled(Box)({
    width: '15%',
    minWidth: '250px',
    minHeight: '100vh',
    maxHeight: '100vh',
    backgroundColor: '#0B0B0F',
    position: 'relative',
    display: 'block'
});

const SidebarFooter = styled(Box)({
    height: '70px',
    position: 'absolute',
    width: '100%',
    bottom: 0
});

const FooterLogo = styled('img')({
    height: '30px'
});

export default function Sidebar(closeDrawer) {
    return (
        <SidebarWrapper>
            <SidebarHeader>
                <SocialMediaMobile/>
                {/*<UserAvatar />*/}
            </SidebarHeader>
            <SidebarLinks closeDrawer={closeDrawer} />
            <SidebarFooter>
                <Stack
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    
                    px={2}
                    sx={{height: '32px', margin: 'auto', 
                    justifyContent: 'center'}}
                >
                    <Logo />
                </Stack>
            </SidebarFooter>
        </SidebarWrapper>
    );
}
