import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Stack, Box, Typography, Container, Tabs, Tab, TabList, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TabPanel, TabImageMobile, TabImageDesktop, ModelingTab } from '../atoms';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainStack = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    alignContent: 'center',
    alignItems: 'center',
    padding: 0,
    pl: 0,
    pr: 0,
    spacing:5
}));

const HorizontalStackWrap = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    alignContent: 'center',
    alignItems: 'start',
    padding: 0,
    position: 'relative',
    
    paddingTop: "28.125% !important",
}));

const MyGrid = styled(Grid)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    alignContent: 'center',
    alignItems: 'start',
    padding: 0,
    position: 'relative',
    
}));

const MyGridItem = styled(Grid)(({ theme }) => ({
    position: 'relative',
    paddingTop: "28.125% !important",
    [theme.breakpoints.down('sm')]: {
        paddingTop: "56.25% !important",
    },
}));
const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: '1vw',
    margin: 'auto',
    fontSize: '2.5vw',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '3vh'
    },
}));



function Examples() {
    const videoRef = useRef(undefined);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    
    const videoTop = isSmallScreen ? "2%": "0%";
    const videoHeight = isSmallScreen ? "98%": "100%";
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    return(
        <MainStack
            spacing={5}>
            <Title>Use Cases</Title>
            <MyGrid container spacing={10}>
            <MyGridItem item xs={12} md={6}>
               <ReactPlayer 
                ref={videoRef} 
                className='wv-hero-video'
                playsInline
                playsinline
                width='99.6%' height={videoHeight} style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    overflow: 'hidden',
                    float: "left",

                
            }} controls={true} muted={true}loop={true} url="https://player.vimeo.com/video/884183068?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"/>
            
            </MyGridItem>
            <MyGridItem item xs={12} md={6}>
            <ReactPlayer 
                ref={videoRef} 
                className='wv-hero-video'
                playsInline
                playsinline
                width='99.6%' height={videoHeight} style={{
                    position: 'absolute',
                    top: videoTop,
                    right: '0',
                    overflow: 'hidden',
                    float: "right",

                }
            } controls={true} muted={true} loop={true} url="https://player.vimeo.com/video/884183935?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"/>
          
                   
            </MyGridItem>
            </MyGrid>
        </MainStack>
    )
}

export default Examples