import Router from "./routes";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from 'react';
import { ScrollToHashElement} from './atoms';

function App() {

    // Import the functions you need from the SDKs you need
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
   
    useEffect(() => {
      
    }, []);
    
    return (
            <BrowserRouter>
                <Router />
                <ScrollToHashElement/>
            </BrowserRouter>
    );
}


export default App;
