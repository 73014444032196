import React, { useState } from 'react';
import { Box, Stack, Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FAQItem} from '../atoms';

const MainStack = styled(Stack)(({ theme }) => ({
            margin: 'auto',
            width:'60%',
            paddingTop: '18vh',
            paddingBottom: '10vh',
            backgroundColor: 'transparent',
     [theme.breakpoints.down('sm')]: {
         
            width:'80%',
     },
}));

const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: '1em',
    fontSize: '3vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
         fontSize: '6vw'
     },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    fontSize: '1.25em',
    marginTop: '2vw !important',
    marginBottom: '2vw !important',
     [theme.breakpoints.down('sm')]: {
        marginBottom: '4vh !important',
        marginTop: '4vh !important',
         fontSize: '1em'
     },
}));

function FAQ() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Container style={{
            
            width: '100%',
            maxWidth: '100%',
                backgroundColor: '#0B0B0F'
            }}>
        <MainStack spacing={2}>
        <Title>Frequently Asked Questions</Title>
        <Subtitle>Welcome to the FAQ page of Waypoint Videos. Here, we've compiled a list of common questions our clients often ask. If you have any additional queries or need further information, please don't hesitate to reach out to us directly.</Subtitle>
        <FAQItem question="What does Waypoint Videos specialize in?" answer="Waypoint Videos specializes in producing high-quality video content for a wide range of purposes, including corporate videos, commercials, promotional videos, documentaries, and more."/>
        
        <FAQItem question="How can I contact Waypoint Videos?" answer="You can contact us through our website's contact form, email us at info@waypointvideos.com."/>
        <FAQItem question="Do you provide video production services for individuals as well as businesses?" answer="Yes, we cater to both individual clients and businesses. Our services are customizable to suit various needs and budgets."/>
        <FAQItem question="What is the typical process for creating a video with Waypoint Videos?" answer="Our video production process involves pre-production (concept development, scripting, planning), production (shooting, recording, 3D modeling), and post-production (rendering, editing, sound design, color grading). We collaborate closely with our clients at every stage."/>
        <FAQItem question="How long does it take to produce a video?" answer="The timeline varies depending on the complexity and length of the video. We will provide you with a project timeline during the initial consultation."/>
        <FAQItem question="How much does video production with Waypoint Videos cost?" answer="Pricing varies depending on the scope of the project, including factors such as video length, complexity, 3D models, and location. We provide customized quotes to ensure you get the best value for your budget."/>
        <FAQItem question="Do you offer package deals or discounts?" answer="We occasionally offer package deals and discounts, which may vary based on the time of year and project requirements. Be sure to ask about our current promotions during your consultation."/>
        <FAQItem question="Can I be involved in the creative process?" answer="Absolutely! We encourage client involvement and collaboration. Your input is valuable in ensuring the final product aligns with your vision and goals."/>
        <FAQItem question="How do I provide feedback during the production process?" answer="We provide a platform for easy and effective communication throughout the project. You can leave feedback on drafts and revisions, ensuring that the video meets your expectations."/>
        <FAQItem question="In what formats will I receive the final video?" answer="We can deliver the final video in various formats, including MP4, MOV, AVI, and more, depending on your preferences and intended use."/>
        <FAQItem question="Can you assist with video distribution and marketing?" answer="Yes, we offer additional services to help with video distribution and marketing strategies, including social media promotion, YouTube optimization, and more."/>
        <FAQItem question="Who owns the rights to the video content created by Waypoint Videos?" answer="By default, our clients retain the rights to the video content we produce for them. We can discuss licensing agreements and usage rights as needed."/>
        <FAQItem question='What are your payment terms?' answer='Payment terms and schedules are typically outlined in our project contracts. We accept various payment methods for your convenience. Contact us for more.'/>
        <FAQItem question="What is your refund policy?" answer="We only offer refunds 24 hours after the order has been placed. Once the video production starts we will not consider any refund requests, and all sales will be final."/>
        <Subtitle style={{
            color: '#9da6a6'
        }}>We hope these FAQs have provided you with valuable information about Waypoint Videos. If you have any further questions or would like to discuss your video project, please don't hesitate to contact us. We look forward to working with you to bring your creative vision to life!</Subtitle>
        </MainStack>
        </Container>
    );
}

export default FAQ;