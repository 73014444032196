import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const MainStack = styled(Stack)(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 'auto',
    alignItems: 'stretch',
}));
const InnerStack = styled(Stack)(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    height: '50%',
    minHeight: '50%',
    padding: 0,
    margin: 'auto',
}));
const BasicTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.25em',
    lineHeight: '1.75em',
    background: '#708496',
    display: 'inline-block',
    width: 'fit-content',
    height: '1.5em',
    padding: '0',
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
    color: 'white',
    borderRadius: '3px',
    overflow: "hidden",
    fontWeight: '700',
    verticalAlign: 'text-bottom',
}));
const PremiumTitle = styled(BasicTitle)(({ theme }) => ({
    background: '#0042FF',
}));

const Text = styled(Typography)(({ theme }) => ({
    fontSize: '1.125em',
    padding: 0,
    textAlign: 'left',
}));

function ModelingTab() {

  return (
    <MainStack spacing={2}>
        <InnerStack spacing={2}>
            <BasicTitle>BASIC</BasicTitle>
            <Text>Simple objects such as small buildings, houses, warehouses with no details. No detailed site plans and simple environments.</Text>
        </InnerStack>
        <InnerStack spacing={2} style={{
            flex: 1,
            justifyContent: 'end',
        }}>
            <PremiumTitle>PREMIUM</PremiumTitle>
            <Text>Complex objects with a lot of details, special building layout and mixed type of buildings. Complex site plans with detailed environments.</Text>
        </InnerStack>

    </MainStack>
  );
}

export default ModelingTab;