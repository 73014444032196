import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { 
  Stack, 
  Box, 
  Typography, 
  Container, 
  TextField, 
  Button, 
  Checkbox, 
  FormGroup, 
  FormControlLabel, 
  InputBase, 
  InputLabel, 
  FormControl, 
  Grid, 
  GridItem  
  } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';





const MainStack = styled(Stack)(({ theme }) => ({
    width: '100% !important',
    margin: 'auto',
    maxWidth: '100% !important',
    height: 'auto',
    background: "linear-gradient(200deg, #0042FF -20%, #111116 80%)",
    padding: '10vh',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '15vw 6vw',
      background: "linear-gradient(230deg, #0042FF -20%, #111116 80%)",
    },
}));

const InnerStack = styled(Stack)(({ theme }) => ({
    width: '100% !important',
    margin: 'auto',
    maxWidth: '100% !important',
    height: 'auto',
    backgroundColor: "#0042FF",
    padding: '0',
    boxSizing: 'border-box',
}));

const LeftStack = styled(Stack)(({ theme }) => ({
    width: '50% !important',
}));
const RightStack = styled(Stack)(({ theme }) => ({
    marginLeft: '3vw',
    width: '100% !important',
    [theme.breakpoints.down('sm')]: {
    marginLeft: '0vw',
    },
}));


const BaseTextField = styled(TextField)(({ theme }) =>({
  fontFamily: 'Calibre !important',
  color: '#fff',
  width: '100%',
  '&:hover label': {
    color: '#9da6a6',
  },
  '& label': {
      fontFamily: 'Calibre',
    color: '#fff',
    fontSize: '1vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4vh',
    },

  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
      fontFamily: 'Calibre',
    '& fieldset': {
    color: '#fff',
      borderColor: '#fff',
      borderWidth: '2px'
    },
    '&:hover fieldset': {
      borderColor: '#9da6a6',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
      color: '#fff',
    },
    '&.Mui-focused fieldset span': {
      paddingRight: '12px',
      color: '#fff !important',
    },
  },
}));

// const BaseTextField = styled(InputBase)(({ theme }) => ({
//   'label + &': {
//     marginTop: theme.spacing(1),
//   },
//   '& .MuiInputBase-input': {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: '#fff',
//     border: '1px solid',
//     borderColor: '#fff',
//     fontSize: '1.1em',
//     width: '100%',
//     margin: '1em auto',
//     color: '#0042FF',
//     padding: '10px 12px 7px 12px',
//     transition: theme.transitions.create([
//       'border-color',
//       'background-color',
//       'box-shadow',
//     ]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: 'Calibre',
//     '&:focus': {
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     },
//   },
// }));
const BaseFormControl = styled(FormControl)(({ theme }) => ({
      width: '100% !important',
      margin: '0.5em auto',
}));

const NameFormControl = styled(FormControl)(({ theme }) => ({
    width: '48% !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
}));
const LastNameFormControl = styled(NameFormControl)(({ theme }) => ({
    marginLeft: '4%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
}));
const Title = styled(Typography)(({ theme }) => ({
    color: '#ffffff',
    marginTop: 0,
    marginBottom: 40,
    fontSize: '2.5vw',
    lineHeight: '1em',
    fontWeight: 700,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
        fontSize: '3vh'
    },
}));

const Subtitle = styled(Title)(({ theme }) => ({
    color: '#ffffff',
    fontSize: '1.7vw',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'left',
    marginBottom: 20,
    marginTop: '0.3vw',
    [theme.breakpoints.down('sm')]: {
    fontWeight: 500,
        fontSize: '2.2vh'
    },
}));

const SendButton = styled(Button)(({ theme }) => ({
    color: '#ffffff',
    fontSize: '1.5vw',
    lineHeight: '1.5vw',
    fontWeight: 500,
    textAlign: 'center',
    backgroundColor: '#0042FF',
    padding: '0.9vw 4vw 0.5vw 4vw',
    width: '25%',
    fontFamily: 'Calibre',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '2.1vh 4vh 1.5vh 4vh',
        fontSize: '2vh'
    },
}));

const MyFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: '#ffffff',
    fontFamily: 'Calibre',
    lineHeight: '1vw',
    fontWeight: 500,
    textAlign: 'center',
    '& span': {
    fontSize: '0.9vw',

    },
    [theme.breakpoints.down('sm')]: {
        '& span': {
          fontSize: '1.4vh !important'

        }
    },
}));
const MyCheckbox = styled(Checkbox)(({ theme }) => ({
    color: '#ffffff',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'center',
}));
function Contact() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState({
    requestDemo: false,
    getPricing: false,
    aerialInspection: false,
    other: false,
  });

  const form = useRef();

  const handleRequestDemoChange = (event) => {
    setCheckbox({...checkbox, requestDemo: event.target.checked});
  };
  const handleGetPricingChange = (event) => {
    setCheckbox({...checkbox, getPricing: event.target.checked});
  };
  const handleAerialInspectionChange = (event) => {
    setCheckbox({...checkbox, aerialInspection: event.target.checked});
  };
  const handleOtherChange = (event) => {
    setCheckbox({...checkbox, other: event.target.checked});
  };


  const sendEmail = (e) => {
    e.preventDefault();

    const selectedOptions = [];

    for (const key in checkbox) {
        if (checkbox[key]) {
            selectedOptions.push(key);
        }
    }

    const resultString = selectedOptions.join(', ');


    let form = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      message: resultString
    }

    emailjs.sendForm('service_25jwqe8', 'template_baedwgg', form, 'Orc92VMxp58muNQJn')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form onSubmit={sendEmail} style={{
      width: '100% !important',
      padding: '0',
      boxSizing: 'border-box',
    }}>
    <MainStack
    >
        <Title>
          Contact Us 
        </Title>
      <Grid container spacing={5}>
      <Grid item xs={12} md={7}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <BaseTextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          
          <BaseTextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            type="email"
            id="email"
            pattern=".+@globex\.com"
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="dense"
          />
        </Grid>
        
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
      <RightStack>
      <Subtitle>Why are you contacting us?</Subtitle>
      <MyFormControlLabel control={<MyCheckbox
          checked={checkbox.requestDemo}
          onChange={handleRequestDemoChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
          label="Request Demo"/>
      <MyFormControlLabel control={<MyCheckbox
          checked={checkbox.getPricing}
          onChange={handleGetPricingChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
          label="Get Pricing"/>
      <MyFormControlLabel control={<MyCheckbox
          checked={checkbox.aerialInspection}
          onChange={handleAerialInspectionChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
          label="Aerial Property Inspection"/>
      <MyFormControlLabel control={<MyCheckbox
          checked={checkbox.other}
          onChange={handleOtherChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
          label="Other"/>
      </RightStack>
      
      </Grid>
      
      <Grid item xs={12} md={7}>
          <SendButton variant="contained" type="submit" >
            Submit
          </SendButton>
        </Grid>
        </Grid>
    </MainStack>
        </form>
  );
};

export default Contact;