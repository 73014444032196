import React from 'react';
import { Stack, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const FooterWrap = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 72,
    borderTop: '1px solid #F0F0F0',

}));
const FooterContent = styled(Stack)(({ theme }) => ({
    maxWidth: 1400,
    width: '100%',
    height: 72,
    margin: '0 auto',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '20px',
    fontSize: '0.9vw',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.3vh',
    },
}));

const SideWrap = styled(Stack)({
    width: '25%',
    height: '52px',
    justifyContent: 'center'
});

const MidWrap = styled(Stack)(({ theme }) => ({
    width: '50%',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
}));

const TermsLink = styled(Link)({
    color: '#F0F0F0',
    textDecoration: 'none',
    width: 'fit-content'
});

export default function Footer() {
    return (
        <FooterWrap>
            <FooterContent>
                <MidWrap direction="row" spacing={2}>
                    <TermsLink to="/faq">FAQ</TermsLink>
                    <Typography>|</Typography>
                    <TermsLink to="/refund-policy">Refund Policy</TermsLink>
                    <Typography>|</Typography>
                    <TermsLink to="/privacy-policy">Privacy Policy</TermsLink>
                    <Typography>|</Typography>
                    <TermsLink to="/terms">Terms and Conditions</TermsLink>
                </MidWrap>
            </FooterContent>
        </FooterWrap>
    );
}
