import React, { useState } from 'react';
import { Box, Stack, Button, Typography, Container, Link } from '@mui/material';
import { styled } from '@mui/material/styles';


const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: '1em',
    fontSize: '3vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
         fontSize: '6vw'
     },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: '2vw !important',
    marginBottom: '2vw !important',
     [theme.breakpoints.down('sm')]: {
        marginTop: '4vh !important',
     },
}));

const Text = styled(Typography)(({ theme }) => ({
    textAlign: 'justify',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
    },    
    '& span': {
        color: '#f2f1fa',
    },
}))

const MainStack = styled(Stack)(({ theme }) => ({
    margin: 'auto',
    width:'50%',
    paddingTop: '18vh',
    paddingBottom: '10vh',
    backgroundColor: 'transparent',
    '& p, h2, ul': {
        fontFamily: 'Calibre',  
    },
     [theme.breakpoints.down('sm')]: {
         
            width:'80%',
     },
}));
function TermsAndCondition() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Stack 
            spacing={5} style={{
            width: '100%',
            maxWidth: '100%',
                backgroundColor: '#0B0B0F',
            }}>
            <MainStack spacing={2} style={{
                margin: 'auto',
                backgroundColor: 'transparent'
            }}> 
                <Title>Terms and Condition</Title>
                <Subtitle>Welcome to Waypoint Videos, a video production company dedicated to providing high-quality video services. Before using our services, please take a moment to review our terms and conditions outlined below:</Subtitle>
      
                
                <Text><span style={{fontWeight:900}}>Acceptance of Terms:</span> By accessing or using our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our services.</Text>
                <Text><span style={{fontWeight:900}}>Service Description:</span> Waypoint Videos offers a range of video production services, including but not limited to video shooting, editing, animation, and post-production. The specifics of the services will be outlined in a separate agreement or proposal for each project.</Text>
                <Text><span style={{fontWeight:900}}>Quotes and Invoices:</span> We will provide you with a detailed quote or estimate for your project. The final cost may vary based on project requirements, add-ons, extras and we will provide you with an invoice for the services rendered.</Text>
                <Text><span style={{fontWeight:900}}>Payment:</span> Payment terms will be specified in the project agreement. A deposit may be required before the commencement of the project, and the balance is typically due upon project completion. Late payments may incur additional fees or affect the project timeline.</Text>
                <Text><span style={{fontWeight:900}}>Cancellation:</span> If you need to cancel a project, please refer to our cancellation policy.</Text>
                <Text><span style={{fontWeight:900}}>Intellectual Property:</span> The rights to any intellectual property, including copyrights and trademarks, created during the project will be specified in the project agreement. Typically, you will own the rights to the final video product, but we may retain the rights to use the project for self-promotion.</Text>
                <Text><span style={{fontWeight:900}}>Confidentiality:</span> We will treat all project information as confidential and will not disclose it to third parties without your consent.</Text>
                <Text><span style={{fontWeight:900}}>Deadlines:</span> We will agree upon project timelines in the project agreement. While we strive to meet deadlines, factors beyond our control may affect project completion times.</Text>
                <Text><span style={{fontWeight:900}}>Revisions:</span> Minor revisions are usually included in the project cost. Major revisions may incur additional fees. Revisions should be requested within a reasonable time frame after project delivery.</Text>
                <Text><span style={{fontWeight:900}}>Termination:</span> We reserve the right to terminate a project if you violate these terms and conditions or engage in unethical or unlawful conduct.</Text>
                <Text><span style={{fontWeight:900}}>Liability:</span> We are not liable for any consequential, incidental, or indirect damages resulting from the use of our services.</Text>
                <Text><span style={{fontWeight:900}}>Governing Law:</span> These terms and conditions are governed by the laws of the European Union. Any disputes will be subject to the exclusive jurisdiction of the courts in the European Union.</Text>
                <Text><span style={{fontWeight:900}}>Changes to Terms:</span> We may update these terms and conditions from time to time. It is your responsibility to review them periodically to stay informed of any changes.</Text>

                <Subtitle>By using our services, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you have any questions or concerns, please contact us before proceeding. Thank you for choosing Waypoint Videos for your video production needs.</Subtitle>
                <Text><span style={{fontWeight:900}}>Last Updated:</span> November 6th, 2023</Text>
                <Stack 
                    spacing={0} style={{
                    width: '100%',
                    maxWidth: '100%',
                    }}>
                <Text>Waypoint Videos</Text>
                <Link href="mailto:info@waypointvideos.com"><Text>info@waypointvideos.com</Text></Link>
                </Stack>
            </MainStack>
        </Stack>
    );
}

export default TermsAndCondition;