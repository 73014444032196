import React from 'react';
import {
    Stack,
    Typography,
    Box,
    List,
    ListItem,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


    // width: '25%',
    // height: '100%',
    // maxWidth: '30%',
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: '90%',
    // },
    
    // alignItems: 'center',
    // alignContent: 'center',
const GridWrap = styled(Stack)(({ theme }) => ({
    background: '#17161C',
    marginTop: 24,
    spacing: 2,
    padding: "7vh",
    paddingTop: "1vh",
    height: '80%',
     [theme.breakpoints.down('sm')]: {
        padding: "7vw",
        paddingTop: "1vw",
     },
}));


const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    fontSize: "2vw",
    fontWeight: 400,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
        fontSize: "2.5vh",
     },
}));

const BulletPoint = styled(Typography)(({theme}) => ({
    fontSize: "1vw",
    lineHeight: 1.08333,
     [theme.breakpoints.down('sm')]: {
         fontSize: '1.5vh',
     },
}));

function ServiceCard({imgSrc, title, bulletPoints, lottieSize}) {
    return(
        <GridWrap>
            <Player
                autoplay
                loop
                src={imgSrc}
                style={{ height: "150px", width: lottieSize }}
                >
            </Player>
            <Title>{title}</Title>
                {bulletPoints.map((bp, index) => {
                    return (
                        <BulletPoint>{bp}</BulletPoint>
                    )
                })}
        </GridWrap>

    )
}


export default ServiceCard;