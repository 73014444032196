import React from 'react';
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Logo } from "../../atoms";
import { AuthButton, UserAvatar, HeaderLinks } from "../../molecules";

const HeaderWrapLaptop = styled(Stack)(({ theme }) => ({
    width: '80%',
    height: '72px !important',
    padding: 0,
    margin: 'auto',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
}));

const SideWrap = styled(Stack)({
    width: '30%',
    height: '32px',
    justifyContent: 'center'
});

const LinksWrap = styled(Stack)({
    flex: 1,
    width: '50%',
    height: '42px',
    alignItems: 'end',
    justifyContent: 'center'
});

export default function HeaderLaptop() {
    return (
        <HeaderWrapLaptop>
            <SideWrap alignItems="start">
                <Logo />
            </SideWrap>
            <LinksWrap>
                <HeaderLinks />
            </LinksWrap>
        </HeaderWrapLaptop>
    );
}
