import { Stack } from "@mui/material";
import { AboutUsLink, PricingLink, ProductionProcessLink, ContactUsButton } from "../atoms";



export default function HeaderLinksLaptop(closeDrawer) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={3}
        >
        <AboutUsLink/>
        <PricingLink/>
        <ProductionProcessLink/>
        <ContactUsButton/>
        </Stack>
    );
}
