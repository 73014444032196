import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#ffffff',
        },
        secondary: {
          main: '#f50057',
        },
        text: {
          primary: '#9da6a6',
        },
      },
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
