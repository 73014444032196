import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';

const LogoImg = styled(Box)(({ theme, selected }) => ({
  transition: 'filter 0.5s ease',
    '&:hover': {
        
        filter: 'hue-rotate(30deg)'
    }
}));





const Logo = () => {
    return (
        <Link 
            style={{height: "100%"}} to="/">
            <LogoImg
                sx={{height: "100%"}}
                component="img"
                src="logo.png"
                alt="logo"
            />
        </Link>
    )
}

export default Logo;