import React, { useState } from 'react';
import { Box, Stack, Button, Container, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import "animate.css/animate.compat.css"
import {ProductionProcess} from './../organisms';

const Header = styled(Stack)(({ theme }) => ({
    backgroundColor: 'transparent',
    maxWidth: '100% !important',
    width: '100%',
    height: 'auto',
    padding: '0 !important',
    marginTop: '72px !important',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
        flexWrap: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '5em'
    },
}))
const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginBottom: 0,
    fontSize: '4vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
        lineHeight: '6vw',
         fontSize: '6vw'
     },
}));

const TitleContainer  = styled(Container)(({ theme }) => ({
    
    margin:'auto',
    padding: '0px',
    paddingLeft: '0px !important',
    marginLeft: '10%',
    display:'flex',
    width:'50%',
    justifyContent: 'left',
    
    [theme.breakpoints.down('sm')]: {
        marginTop: '6vh',
        width: '40% !important', 
    },
}))

function ProductionProcessPage() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Stack 
            spacing={5} style={{
            marginTop: '2vw',
            width: '100%',
            maxWidth: '100%',
            margin: 'auto',
                backgroundColor: '#0B0B0F',
            }}>
                <Header 
                    spacing={0}
                    direction="row">
                    <TitleContainer>
                        <Title 
                            style={{
                                paddingTop: "0.1em",
                                paddingRight: "0.5em",
                                display: "inline-block",
                                width: 'fit-content',
                                background: "linear-gradient(90deg, #1E36E200, #1E36E2)"
                            }}>Production Process</Title>
                    </TitleContainer>

                </Header>
            <ProductionProcess/>
        </Stack>
    );
}

export default ProductionProcessPage;