import React, { useState } from 'react';
import { Box, Stack, Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';


const Header = styled(Stack)(({ theme }) => ({
    position: 'relative',
    backgroundColor: 'transparent',
    maxWidth: '100% !important',
    width: '100%',
    height: 'auto',
    padding: '0 !important',
    paddingLeft: '0px',
    marginTop: '72px !important',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
        flexWrap: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '5em'
    },
}))
const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: 0,
    fontSize: '4vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
        lineHeight: '6vw',
         fontSize: '6vw'
     },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    fontSize: '1.75em',
    textAlign: 'left',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.125em'
    },
}))
const Text = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
    },
}))

const TitleContainer  = styled(Container)(({ theme }) => ({
    position:'absolute',
    margin:'auto',
    padding: '0px',
    paddingLeft: '0px !important',
    left: '20%',
    top: '50%',
    display:'flex',
    width:'30%',
    justifyContent: 'left',
    transform: 'translate(0, -50%)',
    
    [theme.breakpoints.down('sm')]: {
        width: '40% !important', 
    },
}))

const HeroImage = styled(Box)(({ theme }) => ({
    width:'100%',
}))
function AboutUs() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Stack 
            spacing={5} style={{
            width: '100%',
            maxWidth: '100%',
                backgroundColor: '#0B0B0F',
            }}>
                <Header 
                    spacing={0}
                    direction="row">
                    <HeroImage component="img" src="about_us.png"/>
                    <TitleContainer>
                        <Title 
                            style={{
                                paddingTop: "0.1em",
                                paddingRight: "0.5em",
                                display: "inline-block",
                                width: 'fit-content',
                                background: "linear-gradient(90deg, #1E36E200, #1E36E2)"
                            }}>About Us </Title>
                    </TitleContainer>

                </Header>
            <Stack spacing={2} style={{
                width:'60%',
                margin: 'auto',
                marginTop: '5vw',
                marginBottom: '4vw',
                backgroundColor: 'transparent'
            }}>
                
                <Text>Welcome to Waypoint Videos, where we are driven by the belief that real estate information should not only be insightful but also beautifully presented. We are dedicated to helping our clients stay ahead of the competition by providing stunning visuals and easily digestible information.</Text>
                <h2>Our Vision</h2>
                <Text>Our vision is to be the driving force behind a real estate industry where every property shines, every detail is effortlessly understood, and every client has the upper hand in a competitive market. We are committed to bringing innovation and elegance to your real estate journey.</Text>
                <h2>Our Story</h2>
                <Text>Founded by a passionate team of real estate enthusiasts and tech innovators, Waypoint Videos was born out of the desire to transform the way people interact with real estate information. We saw the need for a platform that would not only provide essential data but also bring the real estate experience to life with incredible visual effects.</Text>
                <h2>What Sets Us Apart</h2>
                <Text>Stunning Visuals: We believe that beauty and functionality go hand in hand. Our team of designers and artists work diligently to craft visually captivating presentations of your properties, making them stand out in a crowded market.</Text>
                <Text>Easily Digestible Information: Real estate doesn't have to be complicated. We simplify it for you. Our intuitive interfaces and clear, concise information empower your clients to make informed decisions without the hassle of deciphering complex data.</Text>
                <Text>Innovation at the Core: We are committed to staying at the forefront of technology. Our innovative tools and market insights give you a competitive advantage, enabling you to make strategic moves that set you apart in your market.</Text>
                <h2>Join Us on the Journey</h2>
                <Text>We invite you to embark on this exciting journey with us. Whether you're looking to sell or rent your property, our tools and expertise will guide you toward success.</Text>
                <Text>Welcome to Waypoint Videos, where real estate becomes an immersive and visually delightful experience.</Text>
            </Stack>
        </Stack>
    );
}

export default AboutUs;