import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const CarouselStack = styled(Stack)(({ theme }) => ({
    maxWidth: '80% !important',
    width: '80% !important',
    height: '80%',
    paddingLeft: '5vh',
    paddingRight: '5vh',
    margin: 'auto',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#17161C',
    borderRadius: '5px', 
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
     },
}));

const Quote = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    maxWidth: '100% !important',
    width: '100% !important',
    fontSize: '1.1vw',
    fontWeight: 300,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
    fontSize: '1.55vh',
     },
}));

const Author = styled(Typography)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    fontSize: '1vw',
    fontWeight: 200,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.4vh',
     },
}));

function CarouselItem({quote, author}) {
    return(
        <CarouselStack
            spacing={3}>
            <Quote>"{quote}"</Quote>
            <Author>{author}</Author>

        </CarouselStack>
    )
}

export default CarouselItem
