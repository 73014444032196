import { Stack, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AboutUsLink, PricingLink, ProductionProcessLink, ContactUsButton } from "../atoms";

const SidebarItem = styled(Box)({
    padding: '10px 25px',
});

export default function SidebarLinks() {
    return (
        <Stack>
            <SidebarItem>
                <AboutUsLink/>
            </SidebarItem>
            <SidebarItem>
                <PricingLink/>
            </SidebarItem>
            <SidebarItem>
                <ProductionProcessLink/>
            </SidebarItem>
            <SidebarItem>
                <ContactUsButton/>
            </SidebarItem>
            <SidebarItem>
            </SidebarItem>
        </Stack>
    );
}
