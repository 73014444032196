import { Link, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)(({ theme, selected }) => ({
    fontSize: '1vw',
    fontWeight: 700,
    backgroundColor: '#0042FF',
    color: '#ffffff',
    margin: '1vw auto',
    padding: '0.9vw 1vw 0.5vw 1vw',
    textDecoration: 'none',
    lineHeight: 2,
    fontFamily: 'Calibre', 
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.9vh'
    },
}));


// const ContactButton = styled(Button)(({ theme }) => ({
    
//     fontSize: '1.5vw',
//     lineHeight: '1.5vw',
//     fontWeight: 500,
//     textAlign: 'center',
//     width: '25%',
//     fontFamily: 'Calibre',
//     [theme.breakpoints.down('sm')]: {
//         width: '100%',
//         padding: '2.1vh 4vh 1.5vh 4vh',
//         fontSize: '2vh'
//     },
//     '&:before': {
//         transform: selected ? 'scaleX(1)' : 'scaleX(0)'
//     },
   
// }));


const ContactUsButton = ({ selected }) => {
    const location = useLocation();

    return (
        <StyledLink to="/home#contact-us-section">
            Contact Us
        </StyledLink>
    )
}

export default ContactUsButton;