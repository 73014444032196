import { Link, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)(({ theme, selected }) => ({
    fontSize: '1vw',
    fontWeight: 700,
    color: '#f2f1fa',
    textDecoration: 'none',
    lineHeight: 2,
    '&:before': {
        transform: selected ? 'scaleX(1)' : 'scaleX(0)'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.9vh'
    },
}));

const AboutUsLink = ({ selected}) => {
    const location = useLocation();

    return (
        <StyledLink className="underline-animation" selected={location.pathname === '/about'} to="/about" 
        >
            About Us
        </StyledLink>
    )
}

export default AboutUsLink;