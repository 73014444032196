import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const ContainerWrap = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    height: 'auto',
    padding: "0 !important",
}));
const TitleContainer = styled(Stack)(({ theme }) => ({
    maxWidth: '80% !important',
    width: '80% !important',
    height: 'auto',
    margin: 'auto',
    padding: "0 !important",
}));
const TitleContainerOverlay = styled(Stack)(({ theme }) => ({
    maxWidth: '80% !important',
    width: '80% !important',
    height: 'auto',
    position: 'absolute',
    top: '25%',
    left: '10%',

    margin: 'auto',
    padding: "0 !important",
    transform: 'translate(0, -50%)',
}));


const InnerWrap = styled(Container)(({ theme }) => ({
    marginTop: '72px',
    position: "relative",
    maxWidth: '100% !important',
    width: '100% !important',
    height: 'auto',
    padding: "0 !important",
    paddingTop: "56.25% !important",
    overflow: 'hidden',

}));
const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: 0,
    fontSize: '3vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
    filter: 'drop-shadow(5px 5px 4px #000000)',
     [theme.breakpoints.down('sm')]: {
         fontSize: '3.5vh'
     },
}));


function VideoSlider() {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })
    return (
        <ContainerWrap spacing={0}>
            <InnerWrap>
              <ReactPlayer 
                ref={videoRef} 
                className='wv-hero-video'
                playsInline
                playsinline
                width='100%' height='100%' style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                overflow: 'hidden',
                '& iframe': {
                    aspectRatio: '16/5',

                }
            }} controls={false} muted={true} playing autoplay={true} loop={true} url="https://player.vimeo.com/video/883798006?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"/>
          

            </InnerWrap>
            <TitleContainerOverlay>
                <Title>Awaken Your Estates with</Title>
                <Title 
                    style={{
                        paddingTop: "0.1em",
                        paddingRight: "0.5em",
                        display: "inline-block",
                        width: 'fit-content',
                        background: "linear-gradient(90deg, #1E36E200, #1E36E2)"
                    }}>Dynamic Charm! </Title>
            </TitleContainerOverlay>
            
<script src="https://player.vimeo.com/api/player.js"></script>
        </ContainerWrap>
    )
}

export default VideoSlider

//   <ReactPlayer width='100%' height='100%' style={{
//                 position: 'absolute',
//                 top: '0',
//                 left: '0'
//             }} muted={true} playing  autoplay={true} loop={true} url=""/>

        //         <video
        //         className='wv-hero-video'
        //         ref={videoRef}
        //         loop
        //         autoPlay
        //         muted
        //         playsInline
        //         style={{
        //             position: 'absolute',
        //             top: '0',
        //             width: '100%',
        //             height: '75%',
        //             objectFit: 'cover',
        //         }}
        //         >
        //         <source src="https://player.vimeo.com/video/883798006?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" type="video/mp4"/>
        // </video>
          