import React, { useState } from 'react';
import Carousel from '../molecules/carousel'
import { Stack, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CarouselItem } from '../atoms'
import useMediaQuery from '@mui/material/useMediaQuery';

const StackWrap = styled(Stack)(({ theme }) => ({
    maxWidth: '80% !important',
    width: '80% !important',
    padding: 0,
    margin: 'auto',
}));


const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: 50,
    fontSize: '2.5vw',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '3vh'
    },
}));



function Testimonials() {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    
    const height = isSmallScreen ? "33em": "20em";
    return(
        <StackWrap>
            <Title>Hear What Our Clients Have to Say!</Title>
            <Carousel
                style={{
                    position: 'relative',
                    height: '100%',
                    alignContent: 'center',
                }}
                height={height}
                interval={10000}
                animation="slide"
                indicators={false}
                navButtonsAlwaysVisible={true}
                    navButtonsProps={{    
                    className: "navButtonTestimonials",      // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    style: {
                        margin: 'auto',
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                        width: '50px',
                        height: '50px',
                    }
                }} 
                navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                    style: {
                        margin: 'auto',
                        textAlign: 'center',
                        left: '50%',
                        right: '50%',
                        bottom: '0',
                        top: 'unset',
                        height: '50px',
                        width: '50px',
                    }
                }} 
                >
                <CarouselItem quote="Partnering with Waypoint Videos has elevated our property listings to a new level of sophistication. Their blend of stunning visuals and easily digestible information has not only enhanced our presentations but also attracted a more discerning clientele. Waypoint's innovative approach is a game-changer in the real estate industry." author="Vlatko S. CEO of Multisped DOOEL"/>
                <CarouselItem quote="The intuitive interfaces and clear, concise information provided by Waypoint Videos have been a tremendous asset. They've simplified the process of presenting complex property details, enabling our clients to make informed decisions effortlessly." author="Marjan S. Branch Manager of Duna House"/>
                <CarouselItem quote="Their beautiful presentations highlighted my property's best features, attracting more potential buyers than I could have imagined. The team's dedication to elegance and clarity made the whole process feel effortless." author="Alexandar M. Home Owner"/>
            </Carousel>
        </StackWrap>
    )
}

export default Testimonials