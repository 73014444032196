import React, { useState } from 'react';
import { SwipeableDrawer, useMediaQuery, Stack } from "@mui/material";
 import HeaderLaptop from "./HeaderLaptop";
 import HeaderMobile from "./HeaderMobile";
 import Sidebar from "./Sidebar";

export default function Header() {
    const [show, setShow] = useState(false);

    const isMdSize = useMediaQuery('(max-width: 899px)', { noSsr: true })

    const open = () => setShow(true);
    const close = () => setShow(false);

    return (
        <div 
        style={{
            width: '100%',
            position: 'fixed',
            zIndex: 999,
            top:0,
            backgroundColor: '#0B0B0F',
        }}>
            <HeaderLaptop />
            <HeaderMobile open={open} />
            <React.Fragment>
                <SwipeableDrawer
                    anchor='right'
                    open={show && isMdSize}
                    onClose={close}
                >
                    <Sidebar closeDrawer={close} />
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
