import React, { useState } from 'react';
import { Box, Stack, Button, Typography, Container, Link } from '@mui/material';
import { styled } from '@mui/material/styles';


const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: '1em',
    fontSize: '3vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
         fontSize: '6vw'
     },
}));


const Subtitle = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa'
}));

const Text = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
    },
}))
const Heading = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    textAlign: 'left',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
    },
}))

const MainStack = styled(Stack)(({ theme }) => ({
    margin: 'auto',
    width:'60%',
    paddingTop: '18vh',
    paddingBottom: '10vh',
    backgroundColor: 'transparent',
    '& p, h2, ul': {
        fontFamily: 'Calibre',  
    },
     [theme.breakpoints.down('sm')]: {
         
            width:'80%',
     },
}));
function RefundPolicy() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Stack 
            spacing={5} style={{
            width: '100%',
            maxWidth: '100%',
                backgroundColor: '#0B0B0F',
            }}>
            <MainStack spacing={2} style={{
                margin: 'auto',
                backgroundColor: 'transparent'
            }}> 
                <Title>Refund Policy</Title>
                <Subtitle>Thank you for choosing Waypoint Videos. We are committed to providing you with the best products and services possible. As part of our commitment to transparency and customer satisfaction, we would like to inform you about our refund policy.</Subtitle>
                <Subtitle sx={{
                    
                    marginBottom: '1.5em !important',

                }}>Once an order has been successfully placed on our website, we only offer refunds 24 hours after the order has been placed. Once the video production starts we will not consider any refund requests, and all sales will be final.</Subtitle>
                
                <h2>How to Request a Refund</h2>
                <Text>If you believe you have a valid reason for a refund within the 24-hour period, please follow these steps:</Text>

                <Text>1. Contact Customer Support: Reach out to our customer support team via email at <Link href="info@waypointvideos.com">info@waypointvideos.com</Link> or through our contact page on the website.</Text>
                <Text>2. Provide Details: When contacting us, please provide us with the order ID and reason for refund.</Text>
                <Text>3. Wait for Confirmation: If the refund is requested within the 24 hour timeframe we will review your request we will process the refund according to our policies.</Text>
                <h2>Chargebacks</h2>
                <Text>If a chargeback is initiated with your financial institution or payment provider without first following our refund procedure, we reserve the right to dispute the chargeback and provide evidence of your purchase and our refund policy.</Text>

                <h2>Changes to Refund Policy</h2>
                <Text>Waypoint Videos reserves the right to modify or amend this refund policy at any time without prior notice. Any changes to the policy will be effective immediately upon posting on our website.</Text>
                <Text>By placing an order with us, you acknowledge that you have read, understood, and agreed to this refund policy.</Text>
                <Text>If you have any questions or require clarification regarding our refund policy, please don't hesitate to contact our customer support team at <Link href="info@waypointvideos.com">info@waypointvideos.com</Link>.</Text>



            </MainStack>
        </Stack>
    );
}

export default RefundPolicy;