import React, { useState } from 'react';
import { Box, Stack, Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import ReplyIcon from '@mui/icons-material/Reply';
import { SocialMediaLaptop} from '../organisms';

import {
    VideoSlider,
    Services,
    Testimonials,
    PriceTiers,
    Contact,
    ProductionProcessSection,
    Examples,
} from "../sections"


const SectionContainer = styled(Container)(({ theme }) => ({
    backgroundColor: 'transparent',
    maxWidth: '80% !important',
    width: '80%',
    height: 'auto',
    padding: '0 !important',
    marginTop: '18vh',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
        flexWrap: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '5em'
    },
}))

function Landing() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Stack width='100%' style={{
            margin: 'auto',
            backgroundColor: 'linear-gradient(#303035, #111116 40%)'
        }}>
            <SectionContainer sx={{
                width: '100%',
                maxWidth: '100% !important',
                marginTop: '0 !important'
            }}>
                <VideoSlider/>
            </SectionContainer>
            <SectionContainer>
                <Services/>
            </SectionContainer>
            <SectionContainer>
            <PriceTiers/>
            </SectionContainer>
            <SectionContainer sx={{
                width: '100%',
                maxWidth: '100% !important',
            }}>
                    <ProductionProcessSection/>
            </SectionContainer>
            <SectionContainer>
                <Examples/>
            </SectionContainer>
            <SectionContainer>
            <Testimonials/>
            </SectionContainer>
            
            <SectionContainer id="contact-us-section" name="contact-us-section" sx={{
                
                marginBottom: '18vh',
            }}>
            <Contact/>
            </SectionContainer>
            <SocialMediaLaptop/>
        </Stack>
    );
}

export default Landing;