import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Header, Footer } from "../organisms";

const ContentLayout = styled(Box)({
    width: '100%',
    minHeight: '95%',
    margin: '0 auto',
    backgroundColor: 'transparent',
});

export default function MainLayout() {
    return (
        <>
            <Header />
            <ContentLayout>
                <Outlet />
            </ContentLayout>
            <Footer />
        </>
    );
}
