import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Stack, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImageGIF = styled(Box)(({ theme }) => ({
      position: 'relative',
      height: 'auto',
      padding: "0 !important",

      maxWidth: '50% !important',
      width: '50% !important',
      aspectRatio: '16/9',
      [theme.breakpoints.down('sm')]: {
         display: 'none'
     },
}));


function TabImageDesktop(props: TabPanelProps) {
  const { imgSrc, handleOnLoad, ...other } = props;
    const videoRef = useRef(undefined);
    useEffect(() => {
          videoRef.current.defaultMuted = true;  
    })

  function handleOnReady() {
    console.log('onReady');
    handleOnLoad(false);
  }
  return (
   
        
        <ImageGIF
         >
            <ReactPlayer 
                ref={videoRef} 
                playsInline
                playsinline
                width='100%' height='100%' style={{
                  width: '100%',
                top: '0',
                left: '0',
                right: '0',
                overflow: 'hidden',
                '& iframe': {
                    aspectRatio: '16/5',

                }
            }} 
            onReady={handleOnReady}
            controls={false} muted={true} playing={true} autoplay={true} loop={true} url={`https://player.vimeo.com/video/${imgSrc}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`}/>
          

        </ImageGIF>
      
  );
}

export default TabImageDesktop;