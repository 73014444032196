import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ServiceCard } from '../molecules'

const StackWrap = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    alignContent: 'center',
    alignItems: 'center',
    padding: 0,
    paddingLeft: 0,
}));

const CardsGrid = styled(Grid)(({ theme }) => ({
    maxWidth: '100%',
    width: '100% !important',
    padding: 0,
}));


const CardsGridItem = styled(Grid)(({ theme }) => ({
    padding: 0,
}));

function Services() {

    return (
        <StackWrap>
 
            <CardsGrid container spacing={2}>
            <CardsGridItem item xs={12} md={4}>
                <ServiceCard item imgSrc="lottie\3d_modeling_animation.json"
                lottieSize="150px"
                title="3D Model"
                bulletPoints={[
                    "Architectural Realism",
                    "Interior Design Visualization",
                    "Construction Visualization"
                ]
                }/>
                </CardsGridItem>
            <CardsGridItem item xs={12} md={4}>
                <ServiceCard item imgSrc="lottie\video_animation.json"
                lottieSize="150px"
                title="Video"
                bulletPoints={[
                    "Digestable Infographic Presentation",
                    "Interactive Virtual Walkthroughs",
                    "Social Media Promo Material"
                ]
                }/>
                </CardsGridItem>
            <CardsGridItem item xs={12} md={4}>
                <ServiceCard item imgSrc="lottie\drone_animation.json"
                lottieSize="80px"
                title="Drone"
                bulletPoints={[
                    "Aerial Property Scans",
                    "Virtual Site Inspections",
                    "360° Exterior Views"
                ]
                }/>
                </CardsGridItem>
     
            </CardsGrid>
        </StackWrap>
    )
}

export default Services