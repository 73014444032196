import { Link, useLocation } from "react-router-dom";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faVimeo } from '@fortawesome/free-brands-svg-icons'
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)(({ theme, selected }) => ({
    fontSize: '1vw',
    height: '2vw',
    width: '3vw',
    fontWeight: 700,
    color: '#f2f1fa',
    textDecoration: 'none',
    lineHeight: 2,
    '&:before': {
        transform: selected ? 'scaleX(1)' : 'scaleX(0)'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.9vh'
    },
}));

const VimeoLink = ({ size}) => {
    const location = useLocation();

    return (
        <StyledLink className="wv-fontawesome" to="https://vimeo.com/user211036115" target="_blank"
        ><FontAwesomeIcon icon={faVimeo}  size={size}/>
        </StyledLink>
    )
}

export default VimeoLink;