import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Typography, Container, Tabs, Tab, TabList } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TabPanel, TabImageMobile, TabImageDesktop, ModelingTab } from '../atoms';

const MainStack = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    alignContent: 'center',
    alignItems: 'start',
    padding: 0,
}));

const HorizontalStackWrap = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    alignContent: 'center',
    alignItems: 'start',
    padding: 0,
}));
const VerticalStackWrap = styled(Stack)(({ theme }) => ({
    maxWidth: '50%',
    width: '50%',
    alignSelf: 'stretch',
    minHeight: '100%',
    alignContent: 'top',
    alignItems: 'start',
    padding: 0,
     [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%',
        marginLeft: '0px !important',
     },
}));

const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: 0,
    fontSize: '2.5vw',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
        fontSize: '3vh',
    },
}));
const TabText = styled(Typography)(({ theme }) => ({
    fontSize: '0.9vw',
    padding: 0,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.4vh',
    },
}));
const MyTab = styled(Tab)(({ theme }) => ({
    fontSize: '1vw',
    fontFamily: 'Calibre',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.4vh',
    },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function PriceTiers() {
    const [value, setValue] = React.useState(0);
    const [extrasValue, setValueExtras] = React.useState(0);
    const [packageVideo, setPackageVideo] = React.useState("884111469")
    const [extrasVideo, setExtrasVideo] = React.useState("884116302")

    const [packageUnderline, setPackageUnderline] = React.useState("#1E36E2")

    
    const [isLoading, setLoading] = React.useState(false)

    const packageUnderlines = [
        "#1E36E2",
        "#676C8E",
        "#8E8D8E",
    ]

    const packageVideos = [
        "884111469",
        "884112872",
        "884104761",
    ]
    const extrasVideos = [
        '884116302',
        '884118051',
    ]

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if(isLoading) {
            return;
        }
        setLoading(true);
        setValue(newValue);
        setPackageVideo(packageVideos[newValue])
        setPackageUnderline(packageUnderlines[newValue])
    };

    const handleChangeExtras = (event: React.SyntheticEvent, newValue: number) => {
        if(isLoading) {
            return;
        }
        setLoading(true);
        setValueExtras(newValue);
        setExtrasVideo(extrasVideos[newValue])
    };

    function handleOnLoad(loading) {
        setLoading(false);
    }

    return(
        <MainStack
            spacing={15}>
            <HorizontalStackWrap direction="row" spacing={10}>
                <VerticalStackWrap spacing={2}>
                    <Title>Package deals</Title>
                    <Tabs TabIndicatorProps={{style: {backgroundColor: "#1E36E2"}}} value={value} onChange={handleChange} aria-label="basic tabs example"
                    style={{
                        marginBottom: '3em'
                    }}>
                        <MyTab label="Premium" {...a11yProps(0)} />
                        <MyTab label="Standard" {...a11yProps(1)} />
                        <MyTab label="Basic" {...a11yProps(2)} />
                    </Tabs>
                    <TabImageMobile imgSrc={packageVideo} handleOnLoad={handleOnLoad}/>
                    <TabPanel value={value} index={0} style={{
                        flex: 1
                    }}>
                        <TabText>For professionals and advanced users seeking top-notch quality and unparalleled capabilities, our Premium 3D Modeling and Video Production package is the ultimate choice. Experience cutting-edge technology, advanced rendering options, and a comprehensive suite of tools to unleash your creativity without limitations. This tier is perfect for demanding projects, large-scale productions, and individuals or companies who prioritize premium performance and exceptional results. </TabText>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TabText>Step up your game with our Standard 3D Modeling and Video Production package, tailored for intermediate users and projects of moderate complexity. Enjoy enhanced tools, additional features, and improved rendering capabilities, allowing you to bring more depth and sophistication to your creations. Whether you're a growing business or an aspiring content creator, the Standard tier provides a balanced blend of functionality and affordability.</TabText>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TabText>Our Basic Video Production package is designed for beginners and small projects. With user-friendly interfaces and essential features, it provides a solid foundation for creating simple and basic video content. Ideal for individuals or businesses with limited experience in the field, this tier offers a cost-effective solution to kickstart your creative journey.</TabText>
                    </TabPanel>
                </VerticalStackWrap>
                    <TabImageDesktop imgSrc={packageVideo} handleOnLoad={handleOnLoad}/>
                    </HorizontalStackWrap>

            <HorizontalStackWrap direction="row" spacing={10} >
                    <TabImageDesktop imgSrc={extrasVideo} handleOnLoad={handleOnLoad}/>
                    
                <VerticalStackWrap spacing={2} >
                    <Title>Extras</Title>
                    <Tabs TabIndicatorProps={{style: {backgroundColor: "#1E36E2"}}} value={extrasValue} onChange={handleChangeExtras} aria-label="basic tabs example"
                    style={{
                        marginBottom: '3em'
                    }}>
                        <MyTab label="3D Walkthrough" {...a11yProps(0)} />
                        <MyTab label="3D Floor Plan" {...a11yProps(1)} />
                    </Tabs>
                    <TabImageMobile imgSrc={extrasVideo} handleOnLoad={handleOnLoad}/>
                    <TabPanel value={extrasValue} index={0} style={{
                        flex: 1
                    }}>
                        <TabText>Embark on an immersive journey with our captivating 3D walkthrough experience. Step into a virtual realm where spaces come to life, meticulously crafted with stunning detail and realism. Whether it's exploring architectural designs, interior spaces, or a virtual environment, our 3D walkthrough seamlessly transports you, allowing you to navigate through your surroundings as if you were there in person. Engage with the intricacies of your envisioned project, gaining a comprehensive understanding of every angle, texture, and dimension. Elevate your visual experience and unlock a new level of engagement with our dynamic and visually stunning 3D walkthroughs.</TabText>
                    </TabPanel>
                    <TabPanel value={extrasValue} index={1}>
                        <TabText>A 3D plan surpasses its 2D counterpart by providing a richer and more immersive understanding of spatial relationships and design elements. Unlike 2D plans that offer a flat representation, 3D plans bring designs to life, allowing stakeholders to visualize structures, interiors, and landscapes in a realistic three-dimensional space. This enhanced perspective aids in better comprehending the scale, proportions, and aesthetics of the project, enabling more informed decision-making during the planning and design phases. Additionally, a 3D plan facilitates improved communication among team members, clients, and collaborators, fostering a deeper appreciation for the envisioned project and minimizing potential misunderstandings.</TabText>
                    </TabPanel>
                </VerticalStackWrap>
                   
            </HorizontalStackWrap>
        </MainStack>
    )
}

export default PriceTiers