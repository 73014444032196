import React, { useState } from 'react';
import { Stack } from "@mui/material";
 import {LinkedinLink, VimeoLink} from "../atoms";
import { styled } from '@mui/material/styles';

const MainStack = styled(Stack)(({ theme }) => ({
    backgroundColor: 'transparent',
    height: 'auto',
    padding: 0,
    top:'50%',
    right: 0,
    alignItems: 'center',
    textAlign: 'center',
    transform: 'translate(0, -50%)',
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    },
}))


export default function SocialMediaMobile() {

    return (
        <MainStack direction="row" spacing={5}>
        <LinkedinLink size='2xl'/>
        <VimeoLink size='2xl'/>
        </MainStack>
       
    );
}
