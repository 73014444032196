import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Container } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ScrollAnimation from '../animateOnScroll/animate-on-scroll';
import useMediaQuery from '@mui/material/useMediaQuery';

import { TabPanel, TabImageMobile, TabImageDesktop, ModelingTab } from '../atoms';


const MainStack = styled(Stack)(({ theme }) => ({
    width:'80%',
    margin: 'auto',
    marginTop: '5vw',
    marginBottom: '4vw',
    backgroundColor: 'transparent',
    margin: 'auto !important',
     [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
     },
}))
const MyTimeline = styled(Timeline)(({ theme }) => ({
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 0,
    },
}))

const MyTimelineContent = styled(TimelineContent)(({ theme }) => ({
    alignSelf: 'center',
}))
const MyTimelineItem = styled(TimelineItem)(({ theme }) => ({
    minHeight: '300px',
}))

const MyTimelineDot = styled(TimelineDot)(({ theme }) => ({
    padding: '0px',
}))

const TimilineTitle = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    fontSize: "1.5vw",
    marginBottom: "0.5em !important",
    fontFamily: "Calibre",
    fontWeight: 600,
     [theme.breakpoints.down('sm')]: {
        fontSize: '1.7vh',
     },
}));

const TimilineText = styled(Typography)(({theme}) => ({
    fontFamily: "Calibre",
    fontSize: "0.9vw",
    margin: 'auto',
    textWrap: 'balance',
    lineHeight: 1.308333,
     [theme.breakpoints.down('sm')]: {
        fontSize: '1.3vh'
     },
}));
function ProductionProcess() {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    
    const iconMultiplier = isSmallScreen ? 0.6 : 1;

    return(
        <MainStack spacing={5} style={{
            }}>
                     <MyTimeline position="alternate" sx={{
                        margin: 'auto',
                     }}>
                        <MyTimelineItem>
                            <TimelineSeparator>
                            <TimelineConnector />
                            <MyTimelineDot variant="outlined" style= {{
                                padding: (25 * iconMultiplier) + 'px',
                            }}>
                            <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut" initiallyVisible="true" style={{
                                margin: '-10px'
                            }}>
                                <Player
                                    autoplay
                                    loop
                                    src="lottie\cart_blue_animation.json"
                                    style={{ height: (50 * iconMultiplier) + 'px', width: (50 * iconMultiplier) + 'px' }}
                                    >
                                </Player>
                                </ScrollAnimation>
                            </MyTimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <MyTimelineContent sx={{ py: '12px', px: 2 }}>
                            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight" initiallyVisible="true">
                                <TimilineTitle>
                                    Step 1: Order Placed
                                </TimilineTitle>
                                <TimilineText>We review the information you have provided</TimilineText>
                                <TimilineText>(packages or any extras placed in your order).</TimilineText>
                            </ScrollAnimation>
                              
                            </MyTimelineContent>
                        </MyTimelineItem>
                        <MyTimelineItem>
                            <TimelineSeparator>
                            <TimelineConnector />
                             <MyTimelineDot variant="outlined" style= {{
                                padding: (25 * iconMultiplier) + 'px',
                            }}>
                            <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut" initiallyVisible="true" style={{
                                margin: '-10px'
                            }}>
                                <Player
                                    autoplay
                                    loop
                                    src="lottie\drone_blue_animation.json"
                                    style={{ height: (50 * iconMultiplier) + 'px', width: (50 * iconMultiplier) + 'px' }}
                                    >
                                </Player>
                                </ScrollAnimation>
                            </MyTimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <MyTimelineContent>
                            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft" initiallyVisible="true">
                                <TimilineTitle>
                                    Step 2: Raw Footage Capturing
                                </TimilineTitle>
                                <TimilineText>Booking drone* flight or capturing satelite* footage depending on your needs.</TimilineText>
                                <TimilineText>*Drone footage depends on the weather condition.</TimilineText>
                                <TimilineText>*Satellite footage being 3D depends on the location of your building.</TimilineText>
                            </ScrollAnimation>
                            </MyTimelineContent>
                        </MyTimelineItem>
                        <MyTimelineItem>
                            <TimelineSeparator>
                            <TimelineConnector />
                             <MyTimelineDot variant="outlined">
                            <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut" style={{
                                margin: '-10px'
                            }}>
                                 <Player
                                    autoplay
                                    loop
                                    src="lottie\video_blue_animation.json"
                                    style={{ height: (100 * iconMultiplier) + 'px', width: (100 * iconMultiplier) + 'px' }}
                                    >
                                </Player>
                                </ScrollAnimation>
                            </MyTimelineDot>
                            <TimelineConnector/>
                            </TimelineSeparator>
                            <MyTimelineContent>
                            
                            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">
                                <TimilineTitle>
                                    Step 3: Video Production
                                </TimilineTitle>
                                <TimilineText>Once the drone / satellite footage is complete,</TimilineText>
                                <TimilineText>video production starts. The information provided gets</TimilineText>
                                <TimilineText>implemented with stunning visuals, highlighting everything important.</TimilineText>
                          
                                </ScrollAnimation>
                            </MyTimelineContent>
                        </MyTimelineItem>
                        <MyTimelineItem>
                            <TimelineSeparator>
                            <TimelineConnector />
                             <MyTimelineDot variant="outlined" style= {{
                                padding: (25 * iconMultiplier) + 'px',
                            }}>
                            <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut" style={{
                                margin: '-10px'
                            }}>
                                <Player
                                    autoplay
                                    loop
                                    src="lottie\review_blue_animation.json"
                                    style={{ height: (50 * iconMultiplier) + 'px', width: (50 * iconMultiplier) + 'px' }}
                                    >
                                </Player>
                                </ScrollAnimation>
                            </MyTimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <MyTimelineContent>
                            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
                                <TimilineTitle>
                                    Step 4: Client Review
                                </TimilineTitle>
                                <TimilineText>Video is sent for client review, adding or making any</TimilineText>
                                <TimilineText>necessary changes before delivering a final version.</TimilineText>
                                </ScrollAnimation>
                            </MyTimelineContent>
                        </MyTimelineItem>
                        <MyTimelineItem>
                            <TimelineSeparator>
                            <TimelineConnector/>
                            <MyTimelineDot variant="outlined" style= {{
                                paddingLeft:(22 * iconMultiplier) + 'px',
                                paddingTop:(22 * iconMultiplier) + 'px',
                                paddingRight:(28 * iconMultiplier) + 'px',
                                paddingBottom:(28 * iconMultiplier) + 'px',
                            }}>
                            <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut" style={{
                                margin: '-10px'
                            }}>
                                 <Player
                                    autoplay
                                    loop
                                    src="lottie\final_arrow_animation.json"
                                    style={{ height: (50 * iconMultiplier) + 'px', width: (50 * iconMultiplier) + 'px' }}
                                    >
                                </Player>
                                </ScrollAnimation>
                            </MyTimelineDot>
                            <TimelineConnector />
                            </TimelineSeparator>
                            <MyTimelineContent>
                            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">
                                <TimilineTitle>
                                    Step 5: Final Product
                                </TimilineTitle>
                                <TimilineText>Once the client is satisfied with the results, a final video is provided.</TimilineText>
                                <TimilineText>(Uploaded file and a Vimeo link hosted by us)</TimilineText>
                                </ScrollAnimation>
                            </MyTimelineContent>
                        </MyTimelineItem>
                    </MyTimeline>
            </MainStack>
    )
}

export default ProductionProcess