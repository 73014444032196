import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Stack, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImageGIF = styled(Box)(({ theme }) => ({
    marginBottom: '2em !important',
      position: 'relative',
      height: 'auto',
      padding: "0 !important",
    
      aspectRatio: '16/9',
      maxWidth: '100% !important',
      width: '100% !important',
      [theme.breakpoints.up('sm')]: {
         display: 'none'
     },
}));


function TabImageMobile(props: TabPanelProps) {
  const { imgSrc, value, index, ...other } = props;
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

  return (
   
        <ImageGIF hidden={value !== index}>
              <ReactPlayer 
                ref={videoRef} 
                playsInline
                playsinline
                width='100%' height='100%' style={{
                  width:'100%',
                top: '0',
                left: '0',
                right: '0',
                overflow: 'hidden',
                '& iframe': {
                    aspectRatio: '16/5',

                }
            }} controls={false} muted={true} playing={true} autoplay={true} loop={true} url={`https://player.vimeo.com/video/${imgSrc}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`}/>
           </ImageGIF>
      
  );
}

export default TabImageMobile;