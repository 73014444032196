import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ProductionProcess } from './../organisms';


const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: 50,
    fontSize: '2.5vw',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '3vh'
    },
}));


function ProductionProcessSection() {
 

    return(
        <Stack spacing={5} style={{
            width: '100%'
            }}>
            <Title>Production Process</Title>
                    <ProductionProcess/>
            </Stack>
    )
}

export default ProductionProcessSection