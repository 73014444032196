import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Stack, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const CarouselStack = styled(Stack)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    padding: '4vh',
    margin: 'auto',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#17161C',
    borderRadius: '5px', 
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
     },
}));

const Question = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    maxWidth: '100% !important',
    width: '100% !important',
    fontSize: '1.125em',
    fontWeight: 300,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
    fontSize: '1em',
     },
}));

const Answer = styled(Typography)(({ theme }) => ({
    maxWidth: '100% !important',
    width: '100% !important',
    fontSize: '1em',
    fontWeight: 200,
    textAlign: 'left'
}));

function FAQItem({question, answer}) {
    return(
        <CarouselStack
            spacing={3}>
            <Question>{question}</Question>
            
                <Answer>{answer}</Answer>

        </CarouselStack>
    )
}

export default FAQItem
