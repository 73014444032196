import { useRoutes } from "react-router-dom";
import { Layout } from "../organisms";
import {
    Landing,
    AboutUs,
    Pricing,
    FAQ,
    PrivacyPolicy,
    TermsAndCondition,
    RefundPolicy,
    ProductionProcessPage,
} from "../pages";

export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    path: "/",
                    element: <Landing />,
                },
                {
                    path: "/home",
                    element: <Landing />,
                },
                {
                    path: "/home",
                    element: <Landing />,
                    scrollTo: '#contact-us-section'
                },
                {
                    path: "/about",
                    element: <AboutUs />,
                },
                {
                    path: "/production-process",
                    element: <ProductionProcessPage />,
                },
                {
                    path: "/pricing",
                    element: <Pricing />,
                },
                {
                    path: "/faq",
                    element: <FAQ />,
                },
                {
                    path: "/privacy",
                    element: <PrivacyPolicy/>,
                },
                {
                    path: "/privacy-policy",
                    element: <PrivacyPolicy/>,
                },
                {
                    path: "/terms",
                    element: <TermsAndCondition/>,
                },
                {
                    path: "/refund-policy",
                    element: <RefundPolicy/>,
                }
            ],
        },
        // {
        //     path: "/",
        //     children: [
        //         {
        //             path: "/signin",
        //             element: <SignIn />,
        //         },
        //         {
        //             path: "/signup",
        //             element: <SignUp />,
        //         },
        //         {
        //             path: "/profile",
        //             element: <Profile />,
        //         },
        //     ],
        // },
        
    ]);
}
