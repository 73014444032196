import React, { useState } from 'react';
import { Box, 
        Stack, 
        Button, 
        Typography, 
        Container, 
        Paper, 
        Table, 
        TableBody, 
        TableCell, 
        TableContainer, 
        TableHead, 
        TableRow, 
        Link, 
        List, 
        ListItem, 
        Divider, 
        ListItemText,
        ListItemAvatar,
        ListSubheader,
        ListHeader,
        Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import FoundationIcon from '@mui/icons-material/Foundation';
import ViewInArIcon from '@mui/icons-material/ViewInAr';


const Header = styled(Stack)(({ theme }) => ({
    backgroundColor: 'transparent',
    maxWidth: '100% !important',
    width: '100%',
    height: 'auto',
    padding: '0 !important',
    marginTop: '72px !important',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
        flexWrap: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '5em'
    },
}))
const Title = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    fontSize: '4vw',
    lineHeight: '0.9em',
    fontWeight: 700,
    textAlign: 'left',
     [theme.breakpoints.down('sm')]: {
        lineHeight: '6vw',
         fontSize: '6vw'
     },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: '#f2f1fa',
    fontSize: '1.75em',
    textAlign: 'left',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.125em'
    },
}))
const Text = styled(Typography)(({ theme }) => ({
    fontSize: '1.5em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1em'
    },
}))

const TitleContainer  = styled(Container)(({ theme }) => ({
    
    margin:'auto',
    padding: '0px',
    paddingLeft: '0px !important',
    marginLeft: '10%',
    display:'flex',
    width:'30%',
    justifyContent: 'left',
    
    [theme.breakpoints.down('sm')]: {
        marginTop: '6vh',
        width: '40% !important', 
    },
}))

const HeroImage = styled(Box)(({ theme }) => ({
    width:'70%',
    
    [theme.breakpoints.down('sm')]: {
        display: 'none',
        width: '60%'
    },
}))
const MyList = styled(List)(({ theme }) => ({
    width:'100%',
    marginTop: '2vw !important',
}))
const MyListSubheader = styled(ListSubheader)(({ theme }) => ({
    backgroundColor: 'transparent',
    fontSize: '1.5em',
}))
const MyTableCell = styled(TableCell)(({ theme }) => ({
    color: '#9da6a6',
    fontFamily: 'Calibre',
    fontWeight: 300,
}))
const MyTableHeader = styled(MyTableCell)(({ theme }) => ({
    fontWeight: 700,
}))
const MyAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: '#f2f1fa',
}))
function Pricing() {
    const [loading, setLoading] = useState(false);
    

    return (
        <Stack 
            spacing={5} style={{
            marginTop: '2vw',
            width: '100%',
            maxWidth: '100%',
                backgroundColor: '#0B0B0F',
            }}>
                <Header 
                    spacing={0}
                    direction="row">
                    <TitleContainer>
                        <Title 
                            style={{
                                paddingTop: "0.1em",
                                paddingRight: "0.5em",
                                display: "inline-block",
                                width: 'fit-content',
                                background: "linear-gradient(90deg, #1E36E200, #1E36E2)"
                            }}>Products</Title>
                    </TitleContainer>

                </Header>
            <Stack spacing={15} style={{
                width:'80%',
                margin: 'auto',
                marginTop: '5vw',
                marginBottom: '4vw',
                backgroundColor: 'transparent'
            }}>
                 <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, backgroundColor: '#17161C', color: 'white' }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <MyTableHeader>Includes</MyTableHeader>
                            <MyTableHeader>Basic</MyTableHeader>
                            <MyTableHeader>Standard</MyTableHeader>
                            <MyTableHeader>Premium</MyTableHeader>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                key="Sample"
                                >
                                <MyTableCell component="th" scope="row">
                                    Sample
                                </MyTableCell>
                                <MyTableCell><Link href="https://youtube.com" target="_blank">Play Video ▶️</Link></MyTableCell>
                                <MyTableCell><Link href="https://youtube.com" target="_blank">Play Video ▶️</Link></MyTableCell>
                                <MyTableCell><Link href="https://youtube.com" target="_blank">Play Video ▶️</Link></MyTableCell>
                            </TableRow>
                            <TableRow
                                key="Timeline"
                                >
                                <MyTableCell component="th" scope="row">
                                    Timeline for First Draft
                                </MyTableCell>
                                <MyTableCell>4-6 Days</MyTableCell>
                                <MyTableCell>6-8 Days</MyTableCell>
                                <MyTableCell>8-10 Days</MyTableCell>
                            </TableRow>
                            <TableRow
                                key="Length"
                                >
                                <MyTableCell component="th" scope="row">
                                   Max Video Length in seconds
                                </MyTableCell>
                                <MyTableCell>90</MyTableCell>
                                <MyTableCell>120</MyTableCell>
                                <MyTableCell>150</MyTableCell>
                            </TableRow>
                            <TableRow
                                key="Footage"
                                >
                                <MyTableCell component="th" scope="row">
                                    Footage Type
                                </MyTableCell>
                                <MyTableCell>4k Drone</MyTableCell>
                                <MyTableCell>4k Drone & 3D Satelite</MyTableCell>
                                <MyTableCell>4k Drone & 3D Satelite & 3D Virtual</MyTableCell>
                            </TableRow>
                            <TableRow
                                key="FreeHosting"
                                >
                                <MyTableCell component="th" scope="row">
                                    Free Video Hosting & Shareable Link
                                </MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                            </TableRow>

                            <TableRow
                                key="DataResearch"
                                >
                                <MyTableCell component="th" scope="row">
                                    Data Reseach: Demographics, Taffic, POIs
                                </MyTableCell>
                                <MyTableCell></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                            </TableRow>
                            <TableRow
                                key="Google Earth 3D Presentation"
                                >
                                <MyTableCell component="th" scope="row">
                                   Google Earth 3D Presentation
                                </MyTableCell>
                                <MyTableCell></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                            </TableRow>
                            <TableRow
                                key="3D Interactive Environment"
                                >
                                <MyTableCell component="th" scope="row">
                                    3D Interactive Environment
                                </MyTableCell>
                                <MyTableCell></MyTableCell>
                                <MyTableCell></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                            </TableRow>
                            <TableRow
                                key="3D Customization"
                                >
                                <MyTableCell component="th" scope="row">
                                    3D Customization
                                </MyTableCell>
                                <MyTableCell></MyTableCell>
                                <MyTableCell></MyTableCell>
                                <MyTableCell><CheckIcon/></MyTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
            
            
            <MyList>
            <MyListSubheader component="h2">
                    Extras
            </MyListSubheader>
                <ListItem>
                    <ListItemAvatar>
                    <MyAvatar>
                        <ThreeDRotationIcon />
                    </MyAvatar>
                    </ListItemAvatar>
                    <ListItemText primary="3D Walkthrough" secondary="Bring spaces to life with interactive virtual tours." />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <MyAvatar>
                        <FoundationIcon />
                    </MyAvatar>
                    </ListItemAvatar>
                    <ListItemText primary="3D Floor Plan" secondary="See blueprints in realistic 3D detail." />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <MyAvatar>
                        <ViewInArIcon />
                    </MyAvatar>
                    </ListItemAvatar>
                    <ListItemText primary="Photogrammetry" secondary="Convert photos into accurate 3D models." />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <MyAvatar>
                        <ThreeSixtyIcon />
                    </MyAvatar>
                    </ListItemAvatar>
                    <ListItemText primary="Drone Inspection" secondary="Get precise aerial views for safety and analysis." />
                </ListItem>
                </MyList>
            </Stack>
        </Stack>
    );
}

export default Pricing;


                            // <TableRow
                            //     key="Customization"
                            //     >
                            //     <TableCell component="th" scope="row">
                            //         Customization Level
                            //     </MyTableCell>
                            //     <MyTableCell>Starter</MyTableCell>
                            //     <MyTableCell>Advanced</MyTableCell>
                            //     <MyTableCell>Elite</MyTableCell>
                            // </TableRow>
                            // <TableRow
                            //     key="Essentials"
                            //     >
                            //     <TableCell component="th" scope="row">
                            //         Waypoint Essentials
                            //     </MyTableCell>
                            //     <MyTableCell><CheckIcon/></MyTableCell>
                            //     <MyTableCell><CheckIcon/></MyTableCell>
                            //     <MyTableCell><CheckIcon/></MyTableCell>
                            // </TableRow>